import clone from "clone";
import baseConfig from "./base-config";
import objectPath from "object-path";
import diff from "deep-diff";
import Api from "~/api";
import ModifiableConfig from "./ModifiableConfig";
import {registerTmbDebugUtil} from "~/util/tmb-debug-util";
import RootDefinition from "~/config/definitions/RootDefinition";
import {exchangeOneTimePassword} from "~/config/one-time-password";

// At some point we'll probably want globalConfig to not be a global object, but it works for now
let globalConfig = clone(baseConfig);
const globalConfigChangeCallbacks = [];

export async function setGlobalConfigAndTranslations() {
    globalConfig = await assembleConfig();

    for (const callback of globalConfigChangeCallbacks) {
        callback();
    }

    registerTmbDebugUtil("config", () => globalConfig);
}

export async function assembleConfig() {
    const config = new ModifiableConfig(baseConfig);
    let configWithoutLocal = undefined;

    // Read environment to decide what modifiers to apply on top of the base config
    const variantName = process.env.REACT_APP_VARIANT || "development";
    const developmentMode = process.env.NODE_ENV === "development";

    // Apply modifiers
    const variantModifier = await import(`~/config/variants/${variantName}`);
    if (developmentMode) console.log(`Applying ${variantName}...`);
    await applyModifier(config, variantModifier, variantName);

    if (developmentMode) {
        const myLocalDevelopmentModifier = await import("~/config/my-local-development");
        if (developmentMode) console.log("Applying my-local-development...");
        configWithoutLocal = config.clone();
        await applyModifier(config, myLocalDevelopmentModifier, "my-local-development");
    }

    // If we have an OTP, exchange it for a token now
    await exchangeOneTimePassword(config.get("api.url"), config.get("api.otpExchangeEndpoint"));

    // Apply any specified API endpoints
    for (const endpoint of config.get("api.configurationEndpoints", [])) {
        if (developmentMode) console.info(`Applying from API: ${endpoint}...`);
        await setFromApi(config, configWithoutLocal, endpoint);
    }

    // Give a final chance to make changes after the API
    config.performAfterApiCallbacks();
    if (configWithoutLocal) configWithoutLocal.performAfterApiCallbacks();

    if (developmentMode) {
        console.info("Final production config:", configWithoutLocal.get());
        console.info("Final local config:", config.get());

        try {
            const configAfterValidation = RootDefinition.validate(config.get());
            const difference = diff(config.get(), configAfterValidation);
            if (difference !== undefined) {
                console.error("Differences detected between currently used config and result from (WIP) config validation:");
                console.error(difference);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return config.get();
}

async function applyModifier(config, modifier, name) {
    if (!modifier.default) {
        throw new Error("Config modifier doesn't have a default export");
    }

    await modifier.default(config);
    config.addAppliedModifier(name);
}

/**
 * Applies configuration changes returned from an API endpoint. The passed config
 * is also used to make API call, so the API must already be configured correctly!
 *
 * TODO: This should really be able to set up an API client using a specific config,
 *       instead of using executeUsingConfig().
 *
 * @param config
 * @param configWithoutLocal
 * @param endpoint
 * @returns {Promise<void>}
 */
export async function setFromApi(config, configWithoutLocal, endpoint) {
    await executeUsingConfig(config, async () => {
        const {response} = await Api.get(endpoint);

        config.setManyVariables(response.variables);
        config.setMany(response.config);

        if (configWithoutLocal) {
            configWithoutLocal.setManyVariables(response.variables);
            configWithoutLocal.setMany(response.config);
        }
    });
}

/**
 * Used for now, because we can't instantiate the API client with a specific config.
 * We might want to change this.
 *
 * @param config
 * @param callback
 * @returns {Promise<void>}
 */
async function executeUsingConfig(config, callback) {
    const originalConfig = globalConfig;
    globalConfig = config.getRaw(); // TODO: We really want to be able to use a custom API client here, so that we don't need getRaw()!
    await callback();
    globalConfig = originalConfig;
}

/**
 * Returns the value of a configuration item.
 *
 * If no default value is specified the item is considered required and a value
 * MUST be set, or an Error is thrown.
 *
 * @param path         path to the configuration item
 * @param defaultValue default value for the item, if none is provided the item is required
 * @returns {*}
 */
export default function config(path, defaultValue) {
    if (arguments.length < 2 && !objectPath.has(globalConfig, path)) {
        throw new Error(`Config value "${path}" is required, but not set!`);
    }

    return objectPath.get(globalConfig, path, defaultValue);
}

/**
 * Changes a configuration item.
 *
 * @param path
 * @param value
 */
config.set = function(path, value) {
    objectPath.set(globalConfig, path, value);
};

config.changeCallback = function(callback) {
    globalConfigChangeCallbacks.push(callback);
}