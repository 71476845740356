import {createStore, applyMiddleware, compose} from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";
import {callGetFeedbackIds, callGetProfiles} from "~/middleware/monitoring";
import {localStorageSearchState} from "~/middleware/local-storage-search-state";

export default function configure(preloadedState) {
    return createStore(
        rootReducer,
        preloadedState,
        compose(
            applyMiddleware(thunkMiddleware, callGetProfiles, callGetFeedbackIds, localStorageSearchState),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );
}
