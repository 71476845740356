import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import objectPath from "object-path";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FormattedMessage} from "react-intl";
import ProgressBar from "~/components/ProgressBar";
import styles from "./match-list.module.scss";
import Checkbox from "~/components/Checkbox";
import uuid from "uuid/v4";
import ResultAnnotation from "~/components/ResultAnnotation";
import {isFunction} from "~/util/misc";
import config from "~/config";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import BackendSelection from "~/util/BackendSelection";

export default class Match extends React.PureComponent {
    static propTypes = {
        match: PropTypes.shape({
            id: PropTypes.string.isRequired,
            score: PropTypes.number.isRequired,
            details: PropTypes.object.isRequired,
            explanations: PropTypes.array,
        }).isRequired,
        annotationKey: PropTypes.string,
        extraColumns: PropTypes.arrayOf(
            PropTypes.shape({
                label: TranslatableLabelPropType.isRequired,
                property: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
            })
        ),
        matchInformationGetters: PropTypes.objectOf(PropTypes.func).isRequired,
        existingSelection: PropTypes.instanceOf(BackendSelection),
        openExternalExistingSelection: PropTypes.func,
        isSelected: PropTypes.bool.isRequired,
        allowSelection: PropTypes.bool.isRequired,
        showExistingSelection: PropTypes.bool.isRequired,
        renderDetailsColumn: PropTypes.func.isRequired,
        renderMatchActions: PropTypes.func.isRequired,
        extraColumnPropertyRoot: PropTypes.func.isRequired,
        isGreenMatch: PropTypes.func.isRequired,
        onView: PropTypes.func.isRequired,
        onSelectedChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isShowingInfo: false,
        };

        this.id = uuid();
    }

    render() {
        const {
            match,
            annotationKey,
            allowSelection,
            showExistingSelection,
            isSelected,
            extraColumns,
            renderDetailsColumn,
            renderMatchActions,
            extraColumnPropertyRoot,
            isGreenMatch,
            matchInformationGetters,
            existingSelection,
            openExternalExistingSelection,
        } = this.props;

        return (
            <tr className={c({[styles.selectable]: allowSelection})} onClick={this.toggleSelected}>
                {allowSelection && showExistingSelection && (
                    <td className={styles.existingSelectionColumn}>
                        <ResultAnnotation
                            entityId={match.id}
                            annotationKey={annotationKey}
                            companyId={matchInformationGetters.companyId(match)}
                            existingSelection={existingSelection}
                            openExternalExistingSelection={openExternalExistingSelection}
                        />
                    </td>
                )}
                {allowSelection && (
                    <td className={styles.selectionColumn}>
                        <Checkbox checked={isSelected} onChange={this.handleSelectedChange} />
                    </td>
                )}
                <td className={styles.relevanceColumn}>
                    <OverlayTrigger
                        placement="right"
                        delay={{show: 250, hide: 400}}
                        overlay={this.renderTooltip}
                    >
                        <ProgressBar
                            className={styles.progress}
                            variant={
                                config("ui.disableScoreColoring") || isGreenMatch(match)
                                    ? "success"
                                    : "warning"
                            }
                            now={match.score}
                            min={0}
                            max={1}
                        />
                    </OverlayTrigger>
                </td>
                <td className={styles.detailsColumn}>{renderDetailsColumn(match)}</td>
                {extraColumns &&
                    extraColumns.map(extraColumn => (
                        <td key={extraColumn.key || extraColumn.property}>
                            {isFunction(extraColumn.property)
                                ? extraColumn.property(extraColumnPropertyRoot(match))
                                : objectPath.get(
                                      extraColumnPropertyRoot(match),
                                      extraColumn.property
                                  )}
                        </td>
                    ))}
                <td className={c(styles.contextToggleColumn, "text-nowrap")}>
                    <button
                        className={c("btn", "btn-sm", "btn-info")}
                        onClick={this.handleViewClick}
                    >
                        <FormattedMessage id="match.details" />
                    </button>
                    {renderMatchActions(match)}
                </td>
            </tr>
        );
    }

    renderTooltip = props => {
        const {match, isGreenMatch} = this.props;

        return (
            <Tooltip id={this.id} {...props} show={props.show.toString()}>
                <FormattedMessage
                    id={isGreenMatch(match) ? "resultTooltip.green" : "resultTooltip.yellow"}
                />
            </Tooltip>
        );
    };

    handleViewClick = e => {
        const {match, onView} = this.props;
        e.preventDefault();
        e.stopPropagation();
        onView(match.id);
    };

    toggleSelected = () => {
        const {allowSelection, isSelected, match, onSelectedChange} = this.props;
        if (!allowSelection) return;
        onSelectedChange(match.id, !isSelected);
    };

    handleSelectedChange = selected => {
        const {match, onSelectedChange} = this.props;
        onSelectedChange(match.id, selected);
    };
}
